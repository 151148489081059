<template>
	<footer class="footer" id="footer">
    <div class="section">
      <div class="wrapper">
        <div class="left">
          <router-link to="/" class="logo">
            <img src="../assets/logo.svg" class="img"/>
          </router-link>
          <div class="desc">
            The website is owned and operated by {{ $parent.settings.requisites }}
          </div>
        </div>
        <div class="right">
          <ul class="nav" v-if="$parent.textPageList.length">
             <template  v-for="item in $parent.textPageList">
              <li class="nav__item" :key="item.id">
                <div class="title-tiny" @click="$parent.goToPage(item.id)">
                    {{item.title}}
                  </div>
              </li>
             </template>
             <li class="nav__item">
                <router-link to="/contact-us" class="title-tiny">
                CONTACT US
                </router-link>
              </li>
          </ul>
          <ul class="social">
            <li class="item" v-for="item in $parent.socials" :key="item.link">
              <a target="__blank" :href="item.link">
                <img :src="item.image" class="img"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="wrapper">
        <div class="left">
          <img src="../assets/mc_symbol.svg" class="img"/>
          <img src="../assets/visa.svg" class="img"/>
          <img src="../assets/3ds.png" class="img"/>
        </div>
        <div class="right">
          <div class="desc">© {{ new Date().getFullYear() }} {{ $parent.settings.copyright }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
		
	},
	mounted() {
    
  }
}
</script>
