<template>
  <main class="main">
    <div class="first-section-container profile">
      <img src="../assets/bg.jpg" class="bg"/>
      <div class="section first-section">
        <div class="wrapper">
          <div class="profile-content">
            <div class="tab-list">
              <router-link to="/profile" class="subtitle active">PROFILE</router-link>
              <router-link to="/history" class="subtitle">HISTORY</router-link>
            </div>
            <div class="profile-info">
              <div class="avatar">
                avatar
                <img :src="$parent.profileData.avatar" class="img"/>
              </div>
              <div class="text">
                <div class="row">
                  <div class="title-tiny-osw">NAME:</div>
                  <div class="desc">{{$parent.profileData.name}}</div>
                </div>
                <div class="row">
                  <div class="title-tiny-osw">STEAM ID:</div>
                  <div class="desc">{{$parent.profileData.steam_id}}</div>
                </div>
                <div class="row">
                  <div class="title-tiny-osw">BALANCE:</div>
                  <div class="desc">{{$parent.profileData.balance}} {{$parent.currency}}</div>
                </div>
              </div>
              <button class="button" @click="$parent.logout">
                <span>LOG OUT</span>
                <img src="../assets/log-out.svg" class="img"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
   
  },
  methods: {
    
  }
}
</script>