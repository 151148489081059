<template>
  <main class="main">
    <div class="hero-section-container">
      <img src="../assets/hero-bg.jpg" class="img"/>
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">RUSTED RAID</div>
          <div class="status-container">
            <span class="title-tiny">SERVER STATUS:</span>
            <div class="status">
              <img src="../assets/orb.svg" class="orb"/>
              <span class="title-tiny">ONLINE</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section welcome-section">
      <div class="wrapper">
        <div class="left">
          <img src="../assets/img1.png" class="img"/>
        </div>
        <div class="right">
          <div class="subtitle">Welcome to the Rusted Raid</div>
          <div class="title">survival RUST server</div>
          <div class="desc">
            RUST is a survival simulation video game created by independent British studio Facepunch. The game was released on Windows and macOS platforms. The game was released in December, 2013. And to this day keeps a big online. <br/><br/>Here you can find in-game items for a more interesting gaming experience on the server, or you can just play on the server for free, GLHF!
          </div>
        </div>
      </div>
    </div>
    <div class="section shop-section" id="shop">
      <div class="wrapper">
        <div class="title">SHOP</div>
        <div class="category-filter title-tiny">
          <span class="c-custom-radio">
              <input id="0" type="radio" v-model="activeCategory" value="0" @change="filterProducts()">
              <label for="0">All</label>
          </span>
          <span class="c-custom-radio" v-for="item in categories" :key="item.id">
              <input :id="item.id" type="radio" v-model="activeCategory" :value="item" @change="filterProducts()">
              <label :for="item.id">{{item.name}}</label>
          </span>
        </div>
        <div class="filters">
          <div class="sort">
            <div class="title-tiny">SORT:</div>
            <div class="select-container" v-if="sortOptions.length">
              <Select2 class="select2 single" v-model="sort" 
              :options="sortOptions" 
              placeholder="Desc"
              @select="selectSort($event)"
              />
            </div>
          </div>
          <div class="search">
            <input type="text" v-model="searchedProductText" @keyup.enter="searchSubmit"/>
            <div class="button" @click="searchSubmit">SEARCH</div>
          </div>
        </div>
        <transition name="fade">
          <div class="product-list" v-if="productList">
            <div class="item" v-for="item in productList.data" :key="item.id" @click="openProductModal(item)">
              <img :src="imgDomain + item.img + '.png'" class="img"/>
              <div class="bottom">
                <span class="title-tiny-osw">{{item.name}}</span>
                <div class="price old-price">
                  <span class="title-tiny-osw">{{item.old_price.toFixed(2)}} {{$parent.currency}}</span>
                </div>
                <span class="price">
                  <span class="title-tiny-osw amount">{{item.price.toFixed(2)}}</span>
                  <span class="title-tiny-osw currancy">{{$parent.currency}}</span>
                </span>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="title-tiny no-products" v-if="productList.data && !productList.data.length">No products</div>
        </transition>
        <pagination v-if="totalProducts" v-model="activePage" :per-page="20" :records="totalProducts" @paginate="changePage"/>
      </div>
    </div>
    <div class="section faq-section" id="faq">
      <div class="wrapper">
        <div class="title">FAQ</div>
        <div class="list">
          <div class="item">
            <img src="../assets/faq1.jpg" class="img"/>
            <div class="title-tiny-osw">Is our server free?</div>
            <div class="desc">It`s completely free to play, and if you want to spice things up a bit, you can buy virtual goods from our in-game shop.</div>
          </div>
          <div class="item">
            <img src="../assets/faq2.jpg" class="img"/>
            <div class="title-tiny-osw">How to contact us</div>
            <div class="desc">Email us at support@rustedraid.com or find us on other social media platforms if you`d like to get in touch. You can go to contacts tab</div>
          </div>
          <div class="item">
            <img src="../assets/faq3.jpg" class="img"/>
            <div class="title-tiny-osw">How to login to the server</div>
            <div class="desc">Installing a legal copy of Rust is a must for accessing our gaming server. After setup is complete, open the server launcher and enter 5.187.7.138:28015 to connect to our server.</div>
          </div>
          <div class="item">
            <img src="../assets/faq4.jpg" class="img"/>
            <div class="title-tiny-osw">How to buy?</div>
            <div class="desc">You just need to log in to our website through your Steam account, replenish the balance and select the item you like.</div>
          </div>
        </div>
        <div class="trustpilot">
          <!-- <span class="title-tiny">See our 15 reviews on</span>
          <img src="../assets/trustPilot.svg" class="img"/> -->
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Pagination from 'vue-pagination-2';
import Select2 from 'vue3-select2-component';

export default {
  name: 'Home',
  props: ['currency', 'currencyCode'],
  components: {
    Pagination,
    Select2
  },
  data: function() {
    return {
      imgDomain: '',
      productList: [],
      activePage: 1,
      totalProducts: null,
      searchedProductText: '',
      activeCategory: '',
      sort: 'price',
      categories: [],
      sortOptions: [
        {
          id: 'price',
          text: 'Desc'
        }, 
        {
          id: '-price',
          text:'Asc'
        }
      ],
    }
  },
  watch: {
    currencyCode: function () {
      this.filterProducts();
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getCategories();
    this.$http.get(process.env.VUE_APP_API + 'items')
    .then((res) => {
      this.productList = res.data;
      this.totalProducts = res.data.meta.total;
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignInModal();
      }
    })
  },
  methods: {
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        console.log(res);
        this.categories = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    selectSort(event) {
      this.sort = event.id;
      this.filterProducts();
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    searchSubmit() {
      this.filterProducts();
    },
    filterProducts() {
      let addSort = 'sort=' + this.sort
      let addPage = '&page=' + this.activePage
      let addSearch = '&filter[name]=' + this.searchedProductText
      let addcCategory;
      if (this.activeCategory == 0) {
       addcCategory = '';
      } else {
        addcCategory = '&filter[category]=' + this.activeCategory.id
      }
      this.$http.get(process.env.VUE_APP_API + 'items?' + addSort + addPage + addSearch + addcCategory)
      .then((res) => {
        this.productList = res.data
        this.totalProducts = res.data.meta.total
      })
      .catch(() => {
        
      })
    },
    openProductModal(product) {
      this.$emit('openProductModal', product)
    }
  }
}
</script>