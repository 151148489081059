<template>
	<div class="menu">
      <div class="wrapper">
		<div class="container">
			<ul class="nav">
				<li class="nav__item" v-for="item in $parent.nav" :key="item.link">
					<div class="title-tiny" @click="scrollToSection(item.link)">
					{{item.title}}
					</div>
				</li>
			</ul>
			<div class="balance" v-if="$parent.isAuth">
				<span class="desc">Balance:</span>
				<button class="button profile-button dark" @click="topUpIsVisible = !topUpIsVisible">
					<span>{{Math.floor($parent.profileData.balance * 100) / 100}} {{$parent.currency}}</span>
					<div class="plus">
					<img src="../assets/plus.svg" class="img"/>
					</div>
				</button>
				<transition name="fade">
					<div class="top-up" v-if="topUpIsVisible">
					<div class="top-up-wrapper">
						<div class="title-tiny-osw">Balance Top Up</div>
            <div v-if="$parent.minOrderAmount" class="title-tiny-osw-min">Minimum {{ $parent.minOrderAmount }} EUR</div>
						<div class="input-container">
						<span class="cur title-tiny">€</span>
						<input type="number"  v-model="amount"/>
						<div class="minus" @click="decrease">
							<img src="../assets/minus-big.svg" class="img"/>
						</div>
						<div class="plus" @click="increase">
							<img src="../assets/plus-big.svg" class="img"/>
						</div>
						</div>
						<button :disabled="$parent.minOrderAmount && $parent.minOrderAmount > amount" @click="toCheckout" class="button" type="button">TOP UP</button>
					</div>
					</div>
				</transition>
			</div>
			<router-link to="/profile" class="button profile-button" v-if="$parent.isAuth">
				<span>PROFILE</span>
				<img src="../assets/profile.svg" class="img"/>
			</router-link>
			<div class="button" v-if="!$parent.isAuth" @click="$parent.openSignInModal">
				<span>LOGIN VIA STEAM</span>
				<img src="../assets/steam.svg" class="img"/>
			</div>
			<div class="select-container" v-if="curr && ($parent.currencyOptions.length > 1)">
				<select class="select currency-select" v-model="curr"
					@change="selectCurr()"
				>
					<option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
				</select>
			</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
	name: 'Menu',
	props: ['currency', 'currencyCode', 'currencyValue'],
	data: function() {
		return {
			topUpIsVisible: false,
			amount: 5,
			curr: ''
		}
	},
	computed: {
		currOptions() {
			return this.$parent.currencyOptions.map(({code}) => code);
		}
	},
	
	watch: {
		currencyCode: function (newValue) {
			this.curr = newValue;
		}
	},
	mounted() {
		this.curr = this.currencyCode;
	},
	methods: {
		selectCurr() {
			this.changeCurrency(this.curr)
			localStorage.setItem("currency", this.curr);
		},
		changeCurrency(item) {
			this.$emit('changeCurrency', item)
		},
		scrollToSection(section) {
			this.$parent.closeMenu();
			this.$emit('scrollToSection', section);
		},
		decrease() { 
			if (this.amount > 0) {
				this.amount = this.amount - 1
			} else {
				this.amount = 0
			}
		},
		increase() {
			this.amount = this.amount + 1
		},
		toCheckout() {
			if (this.amount > 0) {
				this.topUpIsVisible = false;
				this.$emit('setAmount', this.amount);
				this.$router.push({ path: 'cart', query: { amount: this.amount }})
			}
		}
	},
}
</script>
